body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-button {
  border: none;
  background: transparent;

  position: absolute;
  right: 25px;
  top: 35px;

  @media (min-width: 900px) {
    display: none;
  }

  &.open {
    span:first-child {
      transform: rotate(45deg) translate(8px, 8px);
    }

    span:nth-child(2) {
      display: none;
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

/* 'hamburger' bars*/
.menu-bar {
  display: block;
  width: 35px;
  height: 4px;
  background-color: white;
  border-radius: 2px;
  margin-bottom: 7px;

  transition: transform 0.2s;
}

/* Main menu navigation*/
/* Mobile first - links are hidden off to right on small screen until user opens menu */
.menu {
  position: fixed;
  top: 100px;
  right: -100%;
  background: white;
  width: 100%;
  height: calc(100vh - 100px);
  padding: 2em;
  transition: all 0.5s ease-in-out;
  /* Display menu on larger screens always*/
  @media (min-width: 900px) {
    position: unset;
    background: unset;
    width: auto;
    height: auto;
    padding: 0;
    display: flex;
  }

  /* When user clicks, menu is opened with 'open' class*/
  &.open {
    display: flex;
    flex-direction: column;
    right: 0;
  }
}

/* Individual Link styles (mobile first)*/
.menu-link {
  text-decoration: none;
  display: block;
  padding: 0.2em;
  color: black;
  border-bottom: 1px solid transparent; /* Prevent jumping on hover. Always take space*/
  border-top: 1px solid transparent; /* Prevent jumping on hover. Always take space*/

  @media (min-width: 900px) {
    padding: 5px;
    margin: 0.5em;
    color: white;
  }

  &:hover,
  &:focus {
    background-color: #eee;
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;

    @media (min-width: 900px) {
      background-color: unset;
      border-top: 1px solid transparent;
      border-bottom: 1px solid white;
    }
  }
}

.active {
  font-weight: bold;

  &:hover {
    cursor: default;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    background-color: unset;
  }
}

/* Don't animate transition between mobile menu when resizing window */
.transition-stop * {
  transition: none !important;
}
