@charset "UTF-8";
.container {
  padding: 30px;
}

ul.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.images > li {
  position: relative;
  padding-top: 130%;
}

ul.images > li img {
  position: auto-fit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 105%;
  object-fit: cover;
}

@media (max-width: 567px) {
  ul.images {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}
body {
  font-family: "Open Sans", Helvetica, sans-serif;
  color: #2c3e50;
  font-size: 1em;
}

.header-fixed {
  background-color: #e67e22;
  box-shadow: 0 1px 1px #ccc;
  padding: 20px 40px;
  height: 80px;
  color: #fff;
  box-sizing: border-box;
  top: -100px;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}

* {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
}

.header-fixed .header-limiter {
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

/*	The header placeholder. It is displayed when the header is fixed to the top of the
	browser window, in order to prevent the content of the page from jumping up. */
.header-fixed-placeholder {
  height: 80px;
  display: none;
}

/* Logo */
.header-fixed .header-limiter h1 {
  float: left;
  font: normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height: 40px;
  margin: 0;
}

.header-fixed .header-limiter h1 span {
  color: #5383d3;
}

/* The navigation links */
.header-fixed .header-limiter a {
  color: #ffffff;
  text-decoration: none;
}

a {
  color: #2c3e50;
  text-decoration: none;
  font-weight: bold;
}



.header-fixed .header-limiter nav {
  font: 16px Arial, Helvetica, sans-serif;
  line-height: 40px;
  float: right;
}

.header-fixed .header-limiter nav a {
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.9;
}
/* 
.header-fixed .header-limiter nav a:hover {
  opacity: 1;
} */

a:hover {
  text-decoration: underline;
}

.header-fixed .header-limiter nav a.selected {
  color: #608bd2;
  pointer-events: none;
  opacity: 1;
}

/* Fixed version of the header */
body.fixed .header-fixed {
  padding: 10px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

body.fixed .header-fixed-placeholder {
  display: block;
}

body.fixed .header-fixed .header-limiter h1 {
  font-size: 24px;
  line-height: 30px;
}

/*
	 We are clearing the body's margin and padding, so that the header fits properly.
	 We are also adding a height to demonstrate the scrolling behavior. You can remove
	 these styles.
 */
body {
  margin: 0;
  padding: 0;
  height: 1500px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix {
  display: inline-block;
}

/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

/* close commented backslash hack */
.clearLeft {
  clear: left;
}

a {
  color: #08c;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:link {
  text-decoration: none;
}

a:hover {
  color: #ccc;
}

.container {
  margin: 0 auto;
}

h1, h2, h3 {
  color: #222;
  font-weight: 200;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
}

h1 {
  font-size: 30px;
  margin-top: 10px;
  font-family: "Times New Roman", Times, sans-serif;
}

h2 {
  margin: 15px 0 5px 0;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
}

h2.header, .sub {
  text-align: center;
}

h3 {
  margin: 5px 0 5px 0;
  font-family: "Source Sans Pro", sans-serif;
}

header h3 {
  text-align: center;
}

p {
  margin: 10px;
}

iframe {
  margin: 0 auto;
  margin-top: 10px;
  display: block;
  width: 85px;
}

.react-photo-gallery--gallery {
  margin-bottom: 15px;
}

.App {
  padding-top: 28px;
  margin: 0;
}

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.breadcrumb a {
  font-family: "Lato", sans-serif;
  font-size: 10px;
  color: #0b3660;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 0 0 40px;
  text-align: center;
  background: #f4f4f4;
  position: relative;
  display: block;
  border-radius: 5px 0 0 5px;
  float: left;
  min-width: 70px;
  text-indent: 0 !important;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

.header {
  overflow: hidden;
  background-color: #f1f1f1;
  padding: 20px 10px;
}

.header-right {
  float: right;
}

.header-middle {
  float: middle;
}

.header a.active {
  background-color: dodgerblue;
  color: white;
}

.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

.header a.logo1 {
  font-size: 25px;
  font-weight: bold;
}

.header a:hover {
  background-color: #ddd;
  color: black;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.loading-msg {
  font-size: 20px;
  font-weight: 200;
  color: #666;
  width: 130px;
  margin: 0 auto;
}

.loading-msg:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 10px;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "…";
  /* ascii code for the ellipsis character */
  width: 0px;
}

#react-images-container {
  background-color: rgba(0, 0, 0, 0.9);
}

.toggle-select {
  background: #06befa;
  background-image: linear-gradient(to bottom, #06befa, #2980b9);
  border-radius: 3px;
  border: 0;
  font-family: Arial;
  color: #ffffff;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
}

.toggle-select:hover {
  background: #06befa;
  background-image: linear-gradient(to bottom, #06befa, #3498db);
  text-decoration: none;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
#msg-app-loading {
  margin-top: 60px;
}

#msg-loading-more {
  text-decoration: none;
  display: block;
  margin-top: 10px;
}


a {
  color: #2c3e50;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

.header {
  padding: 10.5em 0;
  background-repeat: no-repeat;
}

.hero-image {
  background-image: url("./MM_HENNA_ART/Henna1.png");
  background-color: #cccccc;
  height: 2px;
  background-position: left right;
  background-repeat: no-repeat;
  position: relative;
}

.footer,
.header-intro,
.header-name {
  text-align: center;
}

.header-intro {
  margin-bottom: 0em;
  color: #e67e22;
  font-size: 2.25em;
  text-shadow: 0px 1px 0px #d35400;
}

.header-name {
  margin-top: -0.2em;
  color: #e67e22;
  font-size: 3em;
  text-shadow: 0px 3px 0px #d35400;
  font-weight: 700;
}

.about,
.questions {
  max-width: 37.5em;
  margin: 0 auto;
  margin-top: 3em;
  line-height: 1.5em;
}

.questions h3 {
  color: #e67e22;
}

.footer {
  padding: 4em 0;
  color: #fff;
  background: #e67e22;
  margin-top: 3em;
}

.footer p {
  text-align: center;
}

.social {
  text-align: center;
  margin-left: -2em;
}

.social > li {
  list-style: none;
  display: inline-block;
  margin: 0 1em;
}
/* burger */
.topnav {
  overflow: hidden;
  background-color: #e67e22;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}


